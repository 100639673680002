(ns io.klei.lms.frontend.page.school-admin.registration
  (:require
    [goog.object :as gobject]
    [goog.string :as gstring]
    [re-frame.core :as rf]
    [reagent.core :as r]
    [io.klei.lms.frontend.entity.student :as entities.student]
    [io.klei.lms.frontend.entity.registration :as e.registration]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.page.layout :as layout]
    [io.klei.lms.frontend.feature.registration.by-date :as f.registration.by-date]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn registration-edit-form [{:keys [registration]}]
  (let [^js form_ (rf/subscribe [:feat.registration-edit.sub/form])
        submitting? @(rf/subscribe [:feat.registration-edit.sub/submitting?])
        _ (js/console.log "=== JAIME === registration === " registration)
        values_ (r/atom (utils/nskw->js {:section/id (-> registration :registration/section :section/id)
                                         :level/id (-> registration :registration/level :level/id)
                                         :academic-year/id (-> registration :registration/academic-year-id)
                                         :registration/school-code (-> registration :registration/school-code)}))
        ay-options @(rf/subscribe [:feat.registration-edit.sub/academic-year-select-options])
        level-options @(rf/subscribe [:entity.level.sub/select-options])]
    (fn [{:keys [registration]}]
      (let [ay-id (-> registration :registration/academic-year-id)]
        [ui/form {:ref #(rf/dispatch [:feat.registration-edit.event/set-form %])
                  :layout "vertical"
                  :labelCol {:span 24}
                  :wrapperCol {:span 24}
                  :initialValues @values_
                  :onValuesChange (fn [changed values]
                                    (reset! values_ values))
                  :onFinish (fn [values]
                              (rf/dispatch [:feat.registration-edit.event/edit-registration @form_ (:registration/id registration) values]))}
         [ui/section-heading (str (some-> registration :registration/student :student/student-number)
                                  " - "
                                  (some-> registration :registration/student entities.student/last-first-name))]
         [ui/form-item {:label "Academic Year"
                        :name (utils/nskw->json-key :academic-year/id)
                        :rules [{:required true}]}
          [ui/select {:options ay-options
                      :onChange #(rf/dispatch [:feat.registration-edit.event/change-level
                                               @form_
                                               ay-id
                                               %])}]]
         [ui/form-item
          {:label "School"
           :name (utils/nskw->json-key :registration/school-code)
           :rules [{:required true}]}
          [ui/select {:options e.registration/school-options}]]

         [ui/form-item {:label "Level"
                        :name (utils/nskw->json-key :level/id)
                        :rules [{:required true}]}
          [ui/select {:options level-options
                      :onChange #(rf/dispatch [:feat.registration-edit.event/change-level
                                               @form_
                                               ay-id
                                               %])}]]
         [ui/form-item {:label "Section"
                        :name (utils/nskw->json-key :section/id)}
          [ui/select {:options @(rf/subscribe [:entity.section.sub/by-ay-level-id-select-options
                                               ay-id
                                               (gobject/get @values_ (utils/nskw->json-key :level/id))])}]]
         [ui/form-item {:wrapperCol {:span 24}}
          [ui/button {:type "primary"
                      :htmlType "submit"
                      :loading submitting?
                      :style {:margin-left "auto"
                              :display "block"}}
           "Save"]]]))))

(defn registration-content [{:keys [match]}]
  (let [match @(rf/subscribe [:router.sub/match])
        filter' @(rf/subscribe [:feat.registration.by-date.sub/filter])
        reg-id @(rf/subscribe [:feat.registration-edit.sub/selected-registration-id])
        registration @(rf/subscribe [:entity.registration.sub/one-by-id reg-id])]
    [:<>
     [ui/row {:gutter 32}
      [ui/col {:span 12}
       [ui/space {:size 8}
        (when-let [date-range (:date-range filter')]
          [ui/range-picker
           {:value date-range
            :onChange (fn [dates _date-strings]
                        (rf/dispatch [:feat.registration.by-date.event/set-filter
                                      (assoc filter' :date-range dates)]))}])
        [ui/input-search {:placeholder "Search by Learner No."
                          :allowClear true
                          :onSearch (fn [value e]
                                      (rf/dispatch [:feat.registration.by-date.event/set-filter
                                                    (-> filter'
                                                        (assoc :student-number value)
                                                        (assoc :pagination utils/default-pagination))]))}]
        [ui/select {:placeholder "Select Registration Status"
                    :options [{:value nil :label "All Status"}
                              {:value "registered" :label "Registered"}
                              {:value "reserved" :label "Reserved"}]
                    :onChange (fn [val]
                                (rf/dispatch [:feat.registration.by-date.event/set-filter
                                              (-> filter'
                                                (assoc :state val)
                                                (assoc :pagination utils/default-pagination))]))}]]]

      [ui/col {:span 12
               :style {:display "flex"
                       :justify-content "flex-end"}}
       [ui/button {:icon (r/as-element [icon/user-add])
                   :on-click #(rf/dispatch [:router.event/navigate
                                            :page.school-admin/register-new-student
                                            {:path-params (r.utils/org+school-params match)}])}
        "Register Student"]]]
     [ui/row {:gutter 32
              :style {:margin-top "16px"}}
      [ui/col {:span 24}
       [f.registration.by-date/registration-table]
       (when registration
         [ui/drawer
          {:title "Edit Registration"
           :open true
           :onClose #(rf/dispatch [:feat.registration-edit.event/close])}
          [registration-edit-form {:registration registration}]])]]]))


;;------------------------------------------------------------
;; EDIT REGISTRATION
(rf/reg-event-fx
  :feat.registration-edit.event/open
  (fn [{:keys [db]} [_ reg-id ay-id level-id]]
    {:db (assoc db :feat.registration-edit.db/selected-registration-id reg-id)
     :dispatch [:entity.section.event/get-sections-by-ay-level-id ay-id level-id]}))

(rf/reg-event-db
  :feat.registration-edit.event/close
  (fn [db _]
    (assoc db :feat.registration-edit.db/selected-registration-id nil)))

(rf/reg-event-fx
  :feat.registration-edit.event/change-level
  (fn [{:keys [db]} [_ form ay-id level-id]]
    {:dispatch [:entity.section.event/get-sections-by-ay-level-id ay-id level-id]
     :antd.form/set-fields-value [form {(utils/nskw->json-key :section/id) nil}]}))

(rf/reg-event-fx
  :feat.registration-edit.event/edit-registration
  (fn [{db :db} [_ form reg-id values]]
    {:db (assoc db :feat.registration-edit.db/submitting? true)
     :http-xhrio (utils/http-map db {:method :patch
                                     :uri (gstring/format "/registration/%s" reg-id)
                                     :params (utils/stringify-keys
                                               {:academic-year/id (gobject/get values (utils/nskw->json-key :academic-year/id))
                                                :level/id (gobject/get values (utils/nskw->json-key :level/id))
                                                :section/id (gobject/get values (utils/nskw->json-key :section/id))
                                                :registration/school-code (gobject/get values (utils/nskw->json-key :registration/school-code))}
                                               utils/nskw->str)
                                     :on-success [:feat.registration-edit.event/edit-registration-success form]})}))

(rf/reg-event-fx
  :feat.registration-edit.event/edit-registration-success
  (fn [{db :db} [_ form response]]
    (let [reg (utils/normalize-entity-attr-id response :section/id {:registration/section-id :registration/section})]
      {:db (-> db
               (assoc :feat.registration-edit.db/submitting? false)
               ;; TODO: workaround as I need time to think about properly parsing it compatible with instant from transit+json.
               (utils/add (-> reg
                              (dissoc :registration/date)
                              ;; Add the level/id to refresh the subscriptions using registration pull pattern
                              (assoc-in [:registration/level :level/id] (:registration/level-id reg)))))
       :dispatch [:toast-notification {:type :success
                                       :message "Registration has been updated."}]
       :antd/form-reset form})))


(rf/reg-sub
  :feat.registration-edit.sub/selected-registration-id
  (fn [db _]
    (get db :feat.registration-edit.db/selected-registration-id)))

(rf/reg-event-db
  :feat.registration-edit.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.registration-edit.db/form form)))

(rf/reg-sub
  :feat.registration-edit.sub/form
  (fn [db _]
    (get db :feat.registration-edit.db/form)))

(rf/reg-sub
  :feat.registration-edit.sub/submitting?
  (fn [db _]
    (get db :feat.registration-edit.db/submitting? false)))

(rf/reg-sub
  :feat.registration-edit.sub/academic-year-select-options
  :<- [:entity-by-id :academic-year/id]
  (fn [by-id _]
    (->> (vals by-id)
         (filter (fn [ay]
                   (contains? #{"ongoing" "enrollment"} (:academic-year/state ay))))
         (sort-by :academic-year/start-date >)
         (mapv (fn [academic-year]
                 {:value (:academic-year/id academic-year)
                  :text (:academic-year/name academic-year)
                  :label (:academic-year/name academic-year)})))))
